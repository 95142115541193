import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Layout } from "../components/Layout"
import { Seo } from "../components/Seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default function Archive({ data }) {
  const [hoveredImage, setHoveredImage] = useState(null)
  const [hoveredLink, setHoveredLink] = useState(null)

  const projects = data.allMarkdownRemark.nodes

  const filterProjectsByCategory = (projects, category) => {
    return projects.filter((project) => project.frontmatter.category === category)
  }

  const photos = filterProjectsByCategory(projects, "photography")

  let isMobile = false

  // Check only if window is available (client-side rendering)
  if (typeof window !== "undefined") {
    isMobile = /Mobi/i.test(navigator.userAgent)
  }

  const handleMouseEnter = (project) => {
    if (!isMobile) {
      const image = getImage(project.frontmatter.image)
      setHoveredImage(image)
      setHoveredLink(project.id)
    }
  }

  const handleMouseLeave = () => {
    setHoveredImage(null)
    setHoveredLink(null)
  }

  return (
    <Layout>
      <main className="mx-auto">
        <section className="flex flex-col items-center justify-center gap-y-8">
          <div className="text-center">
            <ListHeading title="photography" />
            <ul>
              {photos.map((project) => (
                <li
                  key={project.id}
                  className={`${
                    hoveredImage && hoveredLink !== project.id ? "opacity-20" : "opacity-100"
                  } relative mx-auto w-fit after:absolute after:left-0 after:-bottom-[2px] after:w-full after:origin-right after:scale-x-0 after:border-b-2 after:border-solid after:border-b-black after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65,0.05,0.36,1)] after:content-[''] hover:after:origin-left hover:after:scale-x-100`}
                >
                  <Link
                    to={project.fields.slug}
                    onFocus={() => handleMouseEnter(project)}
                    onMouseOver={() => handleMouseEnter(project)}
                    onMouseOut={handleMouseLeave}
                    onBlur={handleMouseLeave}
                  >
                    <span className="mr-4">{project.frontmatter.order}</span>
                    {project.frontmatter.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </section>
        {/* Background image display */}
        {hoveredImage && (
          <div className="fixed top-1/2 left-1/2 -z-10 min-w-[700px] max-w-[800px] -translate-x-1/2 -translate-y-1/2">
            <GatsbyImage image={hoveredImage} alt="" loading="eager" className="opacity-80" />
          </div>
        )}
      </main>
    </Layout>
  )
}

function ListHeading({ title }) {
  return <h2 className="mb-4 font-bold uppercase">({title})</h2>
}

export const Head = ({ location, data }) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl, image } = useSiteMetadata()
  const description = data.markdownRemark.frontmatter.description || defaultDescription
  const title = data.markdownRemark.frontmatter.title || defaultTitle
  const { pathname } = location

  return (
    <Seo title={`${title} | ${defaultTitle}`} description={description} pathname={pathname}>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          "url": `${siteUrl}${pathname}`,
          "name": title,
          "description": description,
          "headline": "Project Archive",
          "image": `${siteUrl}${image}`
        })}
      </script>
    </Seo>
  )
}

export const archivePageQuery = graphql`
  query ArchivePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        category
        description
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/project/" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          order
          featured
          alt
          category
          image {
            childImageSharp {
              gatsbyImageData(width: 700)
            }
          }
        }
      }
    }
  }
`
